<script setup lang="ts">
const router = useRouter()
const { loggedIn, clear } = useUserSession()
const links = [
  {
    label: 'Профиль',
    to: '/profile',
  },
  {
    label: 'Бомбер',
    to: '/bomber',
  },
  {
    label: 'Выйти',
    click: async () => {
      await clear()
      router.push('/')
    },
  },
]
</script>

<template>
  <UHeader
    :links="loggedIn ? links : []"
    class="lg:rounded-lg max-w-4xl mx-auto bg-opacity-0 lg:top-2"
  >
    <template #logo>
      <div class="flex items-center gap-1">
        <span>
          OpenBomber.com
        </span>
        <Icon name="mdi:beta" class="text-primary" size="24" />
      </div>
    </template>
    <template #right>
      <AuthState v-slot="{ loggedIn }">
        <!-- <div class="mr-2 text-sm" v-if="loggedIn">
                    {{ user?.email }}
                </div> -->
        <UButton
          v-if="!loggedIn"
          label="Войти"
          color="primary"
          to="/login"
        />
        <UColorModeButton v-else class="z-10" />
      </AuthState>
    </template>
  </UHeader>
</template>
